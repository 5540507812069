<template>
    <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
    <div style="width: 447px;">
        <div class="d-flex align-items-center justify-content-center menu mb-3">
        <div
        @click="changeActiveTab('imperial')"
          :class="activeTab=='imperial'?'active':''"
        >
Imperial
    </div>
        <div
         @click="changeActiveTab('metric')"
         :class="activeTab!='imperial'?'active':''"
        >
         Metric
        </div>
      </div>
      <form @submit.prevent="calculateBMI()">
      <div class="d-flex flex-column mt-4" style="gap: 40px;">
      <div class="">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.age" required :min="minMaxValidation[activeTab].age.min" :max="minMaxValidation[activeTab].age.max" type="number" placeholder="Age">
            years
        </div>
      </div>
      <div class="row" v-if="activeTab=='imperial'">
        <div class="col-6">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.height" :min="minMaxValidation[activeTab].height.min" :max="minMaxValidation[activeTab].height.max" required type="number" placeholder="Height">
            ft
        </div>
      </div>
      <div class="col-6">
        <div class="colon-broom-input-control d-flex gap">
            <input required v-model.number="userInformation.in" :min="minMaxValidation[activeTab].in.min" :max="minMaxValidation[activeTab].in.max" type="number" placeholder="in">
            in
        </div>
      </div>
      </div>
      <div class="" v-else>
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.height" :min="minMaxValidation[activeTab].height.min" :max="minMaxValidation[activeTab].height.max" required type="number" placeholder="Height">
            cm
        </div>
      </div>
      <div class="">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.weight" :min="minMaxValidation[activeTab].weight.min" :max="minMaxValidation[activeTab].weight.max" required type="number" placeholder="Weight">
           <span> {{ activeTab=='imperial'?'lb':'kg' }}</span>
        </div>
      </div>
      <div class="">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.desiredWeight" required :min="minMaxValidation[activeTab].desiredWeight.min" :max="minMaxValidation[activeTab].desiredWeight.max" type="text" placeholder="Desired weight">
            {{ activeTab=='imperial'?'lb':'kg' }}
        </div>
    </div>
    <div class="">
        {{ bmi }}
        <div v-if="bmi!=null&&bmi < 18.5">
                <p style="color: red;">You are underweight. Please consult a healthcare provider.</p>
            </div>
            <div v-if="bmi >= 18.5 && bmi < 24.9">
                <p style="color: green;">You are in the normal weight range. Keep up the good work!</p>
            </div>
            <div v-if="bmi >= 25 && bmi < 29.9">
                <p style="color: orange;">You are overweight. You might consider our supplements to support your health.</p>
            </div>
            <div v-if="bmi >= 30">
                <p style="color: red;">You are obese. Please consult a healthcare provider for advice.</p>
            </div>
            <div v-if="userInformation.age > 65 && bmi >= 25">
                <p style="color: blue;">As you are over 65, it is important to manage your weight carefully. Consult your healthcare provider for personalized advice.</p>
            </div>
    </div>
<div class="">
    <button class="colon-broom-button">Continue</button>
</div>
      </div>
    </form>
    </div>
</div>
</template>
<script>
export default {
    data(){
       return {
           activeTab:'imperial',
           minMaxValidation:{
            imperial:{
                age:{
                    min:18,
                    max:120,
                },
                height:{
                    min:4,
                    max:18,
                },
                in:{
                    min:0,
                    max:11,
                },
                weight:{
                    min:88,
                    max:700,
                },
                desiredWeight:{
                    min:88,
                    max:700,
                },
            },
            metric:{
                age:{
                    min:18,
                    max:120,
                },
                height:{
                    min:140,
                    max:250,
                },
               
                weight:{
                    min:40,
                    max:300,
                },
                desiredWeight:{
                    min:40,
                    max:300,
                },
            },
           },
           userInformation:{
            age:'',
            weight:'',
            height:'',
            desiredWeight:'',
            in:'',
           },
           bmi:null,
    }
    
    },
    methods:{
        calculateBMI(){
            this.activeTab=='imperial'?this.calculateBMIImperial():this.calculateBMIMetric()
        },
        changeActiveTab(tab){
            this.activeTab=tab;
           this.userInformation={
            age:'',
            weight:'',
            height:'',
            desiredWeight:'',
            in:'',
           },
           this.bmi=null
        },
        calculateBMIMetric() {
                    if (this.userInformation.weight > 0 && this.userInformation.height > 0) {
                        const heightInMeters = this.userInformation.height / 100;
                       
                        this.bmi = this.userInformation.weight / (heightInMeters * heightInMeters);
                        
                    } else {
                        this.bmi = null;
                        alert('Please enter valid weight and height.');
                    }
                },
                calculateBMIImperial() {
                    if (this.userInformation.weight > 0 && this.userInformation.height > 0&& this.userInformation.in  >= 0 ) {
                        // Convert height to meters
                        const totalHeightInInches = (this.userInformation.height * 12) + this.userInformation.in;
                        const heightInMeters = totalHeightInInches * 0.0254;
                        
                        // Convert weight to kilograms
                        const weightInKg = this.userInformation.weight * 0.453592;
                        
                        this.bmi = weightInKg / (heightInMeters * heightInMeters);
                    } else {
                        this.bmi = null;
                        alert('Please enter valid weight, height, and age.');
                    }
                }
    }
}
</script>
<style scoped>
.menu {
  gap: 20px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 15px;
}
.menu div {
  color: hsl(240, 10%, 50%);
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  position: relative;
}
.menu div.active {
  color: rgb(34, 191, 142);;
}
.menu div.active:after {
  display: block;
  content: "";
  position: absolute;
  bottom: -15px;
  height: 2px;
  /* margin-bottom: 20px; */
  width: 100%;
  background: rgb(34, 191, 142);;
  border-radius: 4px 4px 0px 0px;
}
.colon-broom-input-control{
    border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    color: rgb(0, 0, 0);
    
}
.colon-broom-input-control:focus-within{
    border-bottom: 1px solid rgb(34, 191, 142);
}
.colon-broom-input-control input{
    border:none;
    outline: none;
    flex: 1;
}
.colon-broom-button{
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    letter-spacing: 0.04em;
    position: relative;
    display: block;
    padding: 18px 72px;
    margin: 0px auto;
    text-align: center;
    color: rgb(255, 255, 255);
    background: rgb(34, 191, 142);
    border-radius: 9999px;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
  
    border: none;
    text-transform: uppercase;
    box-shadow: inset 0 0 0 0 rgb(34, 191, 142);
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.colon-broom-button:hover {
  box-shadow: inset 0 100px 0 0 rgb(47 215 162);
}
</style>
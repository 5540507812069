<template>
    <div class="loader_box">
        <div class="loaderDiv"></div>
    </div>

    
</template>

<script>
export default {};
</script>

<style scoped>
.loader_box{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index:999
}

.loaderDiv {

width: 230px;

height: 15px;

border-radius: 20px;

color: #4d1b7e;

border: 2px solid;

position: relative;

}

.loaderDiv::before {

content: "";

position: absolute;

margin: 2px;

inset: 0 100% 0 0;

border-radius: inherit;

background: currentColor;

animation: l6 3s infinite;

}

@keyframes l6 {

100% {inset:0}

}


</style>
